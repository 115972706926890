export function usePoints() {
  const { memberUser } = useUserState();

  const userCurrentPoints = computed(
    () => memberUser.value?.currentPoints ?? 0
  );

  return {
    userCurrentPoints,
  };
}
